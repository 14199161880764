import { call, put } from "redux-saga/effects"
import { type PayloadAction } from "typesafe-actions"

import Liveness, { type LivenessListData } from "../../../apis/Liveness"
import { generateLivenessFailure, generateLivenessSuccess, getLivenessListFailure, getLivenessListSuccess } from "./actions"
import { type Liveness as LivenessType, type LivenessTypes } from "./types"

export interface NewLivenessProps {
  bookingId: string
}

export interface LivenessListProps {
  bookingId?: string
  userId?: string
}

export function * generateLiveness (action: PayloadAction<LivenessTypes, NewLivenessProps>): Generator {
  try {
    const response = yield call(Liveness.newLiveness, action.payload.bookingId)
    yield put(generateLivenessSuccess({ ...(response as LivenessType) }))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.warn("[generateLiveness] ERROR:", err)
    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    if (err instanceof Error) errorMessage = err.message
    yield put(generateLivenessFailure({ errorMessage }))
  }
}

export function * getLivenessList (action: PayloadAction<LivenessTypes, LivenessListProps>): Generator {
  try {
    const response = yield call(Liveness.livenessList, {
      gtsBookingId: action.payload.bookingId,
      gtsUserId: action.payload.userId
    })

    yield put(getLivenessListSuccess((response as LivenessListData).livenessList))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.warn("[getLivenessList] ERROR: ", err.data)
    if ( err.response.status === 401 ) {
      console.log("[Authorization error]: ", err.response)
      window.location.href = "/auth/booking/?authorization=false"
    }
    let errorMessage: string = "Ocorreu um erro, tente novamente mais tarde!"
    if (err instanceof Error) errorMessage = err.message
    yield put(getLivenessListFailure({ message:  errorMessage, status: err.response.status}))
  }
}
