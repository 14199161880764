import "./SearchBooking.css"

import { Button, Col, Form, Input, type InputRef, Modal, Row } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import LogoGTSRedondo from "../../assets/LogoGTSRedondo.svg"
import { texts } from "../../utils/texts"

interface Params {
  authorization?: string;
}

const SearchBooking = (): React.ReactElement => {
  const bookingIdRef = useRef<InputRef>(null)
  const navigate = useNavigate()
  const emailRef = useRef<InputRef>(null)
  const idRef = useRef<InputRef>(null)
  const [pageModal, contextHolder] = Modal.useModal()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState<Params>({})

  async function verifyCredentials () {
    const clientEmail = String(emailRef.current?.input?.value.replace(/\s+/g, '').toLowerCase())
    const clientId = idRef.current?.input?.value.replace(/\s+/g, '')
    const bookingId = bookingIdRef.current?.input?.value.replace(/\s+/g, '')

    setLoading(true)

    await fetch(`${process.env.REACT_APP_API_GTS_ENDPOINT}/validate-credentials`, {
      method: "POST",
      body: JSON.stringify({
        clientId,
        clientEmail,
        bookingId
      })
    }).then(async (response) => {
      const gtsAccessToken = (await response.json())?.gtsAccessToken

      const errorMessage = response?.status === 401 
        ? texts.searchBooking.notAMatch
        :  texts.searchBooking.genericError

      if (response?.status >= 401) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        pageModal.error({
          title: errorMessage,
          onOk: () => { setIsModalOpen(false) },
          open: isModalOpen,
          width: 620
        })
        return null
      }

      localStorage.setItem("@kinto-solutions/gts/id", clientId ?? "")
      localStorage.setItem("@kinto-solutions/gts/email", clientEmail ?? "")
      localStorage.setItem("@kinto-solutions/gts/gtsAccessToken", gtsAccessToken ?? "")

      navigate(`/auth/booking/${bookingId}`)
    }).catch((err) => {
      console.error("Error: ", err)
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      pageModal.error({
        title: texts.searchBooking.genericError,
        onOk: () => { setIsModalOpen(false) },
        open: isModalOpen,
        width: 620
      })
      return null
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorization = urlParams.get('authorization');
    setParams({ authorization: authorization ?? "" })
  },[window])
  
  useEffect(() => {
    if ( params.authorization === "false" ) {
      pageModal.error({
        title: texts.searchBooking.expiredToken,
        onOk: () => { setIsModalOpen(false) },
        open: isModalOpen,
        width: 620
      })
    }
  }, [params])

  return (
    <div className="search-booking">
      <Row align="middle" justify="center">
        <Col span={24}>
            <img src={LogoGTSRedondo} alt="Logo GTS" style={{ maxHeight: "512px", height: "40vh", width: "40vw", marginBottom: "10px" }} />
        </Col>
        <Col xs={16} md={12} xl={8}>
          <Form
            initialValues={{
              remember: true
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onFinish={verifyCredentials}
            disabled={loading}
          >
            <Form.Item
              name="bookingId"
              rules={[
                { required: true, message: "Insira o booking Id!" }
              ]}
            >
              <Input placeholder="Booking ID" ref={bookingIdRef} size="large" maxLength={7} />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Insira o booking Id!" }
              ]}
            >
              <Input placeholder="E-mail do cliente cadastrado no GTS" ref={emailRef} size="large" />
            </Form.Item>
            <Form.Item
              name="id"
              rules={[
                { required: true, message: "Insira o id!" }
              ]}
            >
              <Input placeholder="ID do Cliente" ref={idRef} size="large" />
            </Form.Item>
            <Form.Item>
              <Button id="booking-search-submit-button" htmlType="submit" type="primary">
                Buscar
              </Button>
            </Form.Item>
          </Form>
        </Col>
        {/* <Col span={props.error ? 24 : 0}>
          <span className="error-text">{props.errorMessage}</span>
        </Col> */}
      </Row>
      {contextHolder}
    </div>
  )
}

export default SearchBooking
