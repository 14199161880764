import { action } from "typesafe-actions"

import { type Liveness, LivenessTypes } from "./types"

export const generateLiveness = (data: {
  bookingId: string
}): { type: LivenessTypes.GENERATE_LIVENESS } => action(LivenessTypes.GENERATE_LIVENESS, data)

export const generateLivenessSuccess = (data: Liveness): { type: LivenessTypes.GENERATE_LIVENESS_SUCCESS, payload: Liveness } => action(LivenessTypes.GENERATE_LIVENESS_SUCCESS, data)
export const generateLivenessFailure = (data: { errorMessage: string }): { type: LivenessTypes.GENERATE_LIVENESS_FAILURE } => action(LivenessTypes.GENERATE_LIVENESS_FAILURE, data)

export const getLivenessList = (data: { bookingId?: string, userId?: string }): { type: LivenessTypes.GET_LIVENESS_LIST, payload: { bookingId?: string, userId?: string } } => action(LivenessTypes.GET_LIVENESS_LIST, data)
export const getLivenessListSuccess = (data: Liveness[]): { type: LivenessTypes.GET_LIVENESS_LIST_SUCCESS, payload: Liveness[] } => action(LivenessTypes.GET_LIVENESS_LIST_SUCCESS, data)
export const getLivenessListFailure = (data: { message: string, status: number }): { type: LivenessTypes.GET_LIVENESS_LIST_FAILURE } => action(LivenessTypes.GET_LIVENESS_LIST_FAILURE, data)
