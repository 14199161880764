export const texts = {
  searchBooking: {
    notAMatch:
      "Booking ID não corresponde ao ID de cliente informado. Por favor confirmar as informações.",
    genericError:
      "Obtivemos um erro ao realizar a busca, tente novamente em alguns instantes.",
    expiredToken:
      "Seu token expirou, é preciso fazer a busca pelo Booking ID novamente",
  },
  liveness: {
    newProcess: "Novo processo iniciado com sucesso!",
    newLivenessError: [
      "Não foi possível avaliar este cliente.",
      "Por favor tire o print desta tela, e envie para whatsapp (11) 4118-9959 com o número do booking para constar nas nossas métricas.",
      "Siga com o processo padrão junto ao cliente e obrigado por participar dos testes da nova ferramenta!"
    ],
    qrCodeTile: "Informe o QRCode para o cliente!"
  },
  invoicesList: {
    moreInfo: "Clique no item para mais informações."
  }
};
