import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons"
import { Button, Collapse, Modal, Tag } from "antd"
import { useEffect, useMemo, useRef, useState } from "react"
import { QRCode } from "react-qrcode-logo"
import { connect } from "react-redux"
import { bindActionCreators, type Dispatch } from "redux"

import SimboloKinto from "../../assets/SimboloKinto.svg"
import { type ApplicationState } from "../../store"
import { type Booking } from "../../store/ducks/booking/types"
import * as LivenessActions from "../../store/ducks/liveness/actions"
import { type Liveness, type LivenessState, type LivenessStatus } from "../../store/ducks/liveness/types"
import { censorEmail, censorPhone, toFormatedPlate } from "../../utils/helpers"
import { timeAsDayjs } from "../../utils/time"
import { texts } from "../../utils/texts"

function mapStateToProps (state: ApplicationState): StateProps {
  return ({
    booking: state.bookings.data,
    liveness: state.liveness
  })
}

function mapDispatchToProps (dispatch: Dispatch) {
  return bindActionCreators(LivenessActions, dispatch)
}

interface StateProps {
  booking: Booking
  liveness: LivenessState
}

interface DispatchProps {
  generateLiveness: (payload: {
    bookingId: string
  }) => void

  getLivenessList: (payload: {
    bookingId?: string
    userId?: string
  }) => void
}

type Props = StateProps & DispatchProps

const LivenessView = (props: Partial<Props>): React.JSX.Element => {
  // const [items, setItems] = useState<CollapseProps["items"]>([])
  const [newLivenessModal, contextHolder] = Modal.useModal()
  const qrCodeRef = useRef<QRCode>(null)
  const clientFirstName = props?.booking?._embedded?.user?.personalInfo?.firstName ?? "Não informado"
  const clientLastName = props?.booking?._embedded?.user?.personalInfo?.lastName ?? ""
  const clientFullName = `${clientFirstName} ${clientLastName}`
  const hasVehicleData = props?.booking?._embedded?.vehicle
  const hasUserData = props?.booking?._embedded?.user
  const [isOpen, setIsOpen] = useState(false)

  const now = timeAsDayjs()
  const subtraction = timeAsDayjs(props?.booking?.pickUpDatetime).subtract(3, "hours").diff(now, 'hour')
  const isOutOfTime = subtraction > 2 || subtraction < -2
  const lastItemStatus = props?.liveness?.livenesList?.[props?.liveness?.livenesList.length - 1]?.status
  const statusShouldAbleButton = lastItemStatus === "EXPIRED" || lastItemStatus === "FAILED" || lastItemStatus === undefined
  const statusShouldChangeButtonText = lastItemStatus === "WAITING" || lastItemStatus === "IN PROGRESS" || lastItemStatus === "ANALYSIS" || lastItemStatus === "OCR"
  const disabled = props?.liveness?.loadingNewLiveness || isOutOfTime || !statusShouldAbleButton

  function livenessStatusToElement (status: LivenessStatus) {
    switch (status) {
      case "EXPIRED": return <Tag color="orange">Expirado</Tag>
      case "FAILED": return <Tag color="red">Reprovado</Tag>
      case "SUCCESS": return <Tag color="green">Aprovado</Tag>
      case "IN PROGRESS": return <Tag color="yellow">Aguardando captura</Tag>
      case "OCR": return <Tag color="yellow">OCR</Tag>
      case "WAITING": return <Tag color="yellow">Aguardando</Tag>
      case "ANALYSIS": return <Tag color="yellow">Em análise</Tag>
    }
  }

  function livenessItemToElement (item: Liveness) {
    return <div>
        <p>Status: {livenessStatusToElement(item.status)}</p>
        <p>Criado em: {timeAsDayjs(item.createdAt).subtract(3, "hours").format("DD/MM/YYYY HH:mm")}</p>
        <p>Ultima Atualização em: {timeAsDayjs(item.updatedAt).subtract(3, "hours").format("DD/MM/YYYY HH:mm")}</p>
        <p>Expira em: {timeAsDayjs(item.expiresAt).subtract(3, "hours").format("DD/MM/YYYY HH:mm")}</p>
        { (item.status === "WAITING" || item.status === "IN PROGRESS") && (<Button onClick={() => { openLivenessQRCode(item._id) }}>Abrir QRCode</Button>) }
    </div>
  }

  function openLivenessQRCode (id: number) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    newLivenessModal.confirm({
      title: texts.liveness.newProcess,
      icon: <CheckCircleFilled style={{ color: "green" }} />,
      content: <div>
        {texts.liveness.qrCodeTile}

        <QRCode
          value={`${process.env.REACT_APP_LIVENESS_URI}?id=${id}`}
          size={300}
          logoWidth={50}
          logoHeight={35}
          ecLevel='M'
          fgColor={"#00708d"}
          ref={qrCodeRef}
          logoImage={SimboloKinto}
          removeQrCodeBehindLogo
          id="pixqrcode"
        />
        <p><a href={`${process.env.REACT_APP_LIVENESS_URI}?id=${id}`} target="_blank">{`${process.env.REACT_APP_LIVENESS_URI}?id=${id}`}</a></p>
      </div>
    })
  }

  function generateItemsList () {
    return (props?.liveness?.livenesList ?? []).map((item, index) => ({
      key: index,
      label: <div className="liveness-history-item"><span>{timeAsDayjs(item.createdAt).subtract(3, "hours").format("DD/MM/YYYY HH:mm")}</span>{livenessStatusToElement(item.status)}</div>,
      children: livenessItemToElement(item)
    }))
  }

  function getLivenessList() {
    props?.getLivenessList!({
      bookingId: props?.booking?.id
    })
  }

  const items = useMemo(() => generateItemsList(), [props?.liveness?.livenesList])

  useEffect(() => {
    if (!props?.liveness?.loadingNewLiveness) {
      if (props?.liveness?.newLiveness?._id) {
        openLivenessQRCode(props?.liveness.newLiveness._id)
      }
    }
  }, [props?.liveness?.loadingNewLiveness])

  useEffect(() => {
    if (props?.liveness?.livenessSuccess && props?.booking?.id) {
      getLivenessList();
    }
  }, [props?.liveness?.livenessSuccess])

  useEffect(() => {
    if (props?.liveness?.errorNewLiveness) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      newLivenessModal.error({
        title: "Erro ao iniciar o Reconhecimento",
        icon: <CloseCircleFilled style={{ color: "red" }} />,
        onOk: () => { setIsOpen(false) },
        open: isOpen,
        content: <div>
          {texts.liveness.newLivenessError?.map((text) => {
            return (
              <p>
                {text}
              </p>
          )})}
        </div>,
        width: 520
      })
    }
  }, [])

  const   handleNewLiveness = () => {
    if (!disabled) {
      props?.generateLiveness!({
        bookingId: props?.booking?.id ?? ""
      })
    }
  }

  useEffect(() => {
    if (props?.booking?.id) {
      props?.getLivenessList!({
        bookingId: props?.booking?.id
      })
    }
  }, [])
  
  return (
    <div className="liveness-workspace">
      <div className="liveness-card">
        <div className="liveness-card-info">
          Cliente: {clientFullName}<br />
          Data prevista:  {timeAsDayjs(props?.booking?.pickUpDatetime).subtract(3, "hours").format("DD/MM/YYYY HH:mm")}<br />
          Veículo: {props?.booking?._embedded?.vehicle?.model?.fullName}<br />
          Placa: {toFormatedPlate(props?.booking?._embedded?.vehicle?.registrationPlate)}<br />
          E-mail: <b>{censorEmail(props?.booking?._embedded?.user?.email)}</b><br />
          Celular: <b>{censorPhone(props?.booking?._embedded?.user?.personalInfo?.mobilePhoneNumber)}</b><br />
        </div>
        <div className="liveness-card-email">
         { hasVehicleData && hasUserData &&
          <Button
            type="primary"
            disabled={disabled}
            onClick={handleNewLiveness}
            loading={props?.liveness?.loadingNewLiveness}
          >
              { statusShouldChangeButtonText ? "Reconhecimento facial em andamento" : "Iniciar Reconhecimento" }
          </Button>}
        </div>
      </div>

      <div className="liveness-history">
        <Button
          type="link"
          className="search-list"
          onClick={getLivenessList}
          disabled={props?.liveness?.loadingLivenessList}
          loading={props?.liveness?.loadingLivenessList}
        >
          Atualizar Lista
        </Button>
        <Collapse accordion items={items} />
      </div>
      {contextHolder}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LivenessView)
