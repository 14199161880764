/**
 * Action types
 */
export enum LivenessTypes {
  "GENERATE_LIVENESS" = "@liveness/GENERATE_LIVENESS",
  "GENERATE_LIVENESS_SUCCESS" = "@liveness/GENERATE_LIVENESS_SUCCESS",
  "GENERATE_LIVENESS_FAILURE" = "@liveness/GENERATE_LIVENESS_FAILURE",
  "GET_LIVENESS_LIST" = "@liveness/GET_LIVENESS_LIST",
  "GET_LIVENESS_LIST_SUCCESS" = "@liveness/GET_LIVENESS_LIST_SUCCESS",
  "GET_LIVENESS_LIST_FAILURE" = "@liveness/GET_LIVENESS_LIST_FAILURE"
}

/**
 * Data types
 */

export type LivenessStatus = "EXPIRED" | "SUCCESS" | "IN PROGRESS" | "FAILED" | "WAITING" | "OCR" | "ANALYSIS"

export interface Liveness {
  _id: number
  gtsBookingId: string
  gtsUserId: string
  status: LivenessStatus
  expiresAt: Date
  message: string
  history: ILivenessHistory[]
  createdAt: Date
  startedAt: Date
  reprovedAt: Date
  approvedAt: Date
  updatedAt: Date
}

export interface ILivenessHistory {
  changedAt: Date
  from: LivenessStatus
  to: LivenessStatus
}

/**
 * State type
 */
export interface LivenessState {
  readonly livenesList: Liveness[]
  readonly loadingLivenessList: boolean
  readonly errorLivenessList: boolean

  readonly newLiveness?: Liveness
  readonly loadingNewLiveness: boolean
  readonly errorNewLiveness: boolean
  readonly errorMessageNewLiveness: string

  readonly livenessSuccess: boolean
  readonly errorLivenessListData: string
}
